#uni-id-reviews{
    font-weight: 400;
    font-size: 40px;
    margin-bottom: 10px;
}
#uni-id-reviews{
  margin-top: 10px;
}
#overall-ratings{
    display: flex;
    flex-direction: row;
}
#overall-ratings-individual{
    width: 45px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    color: white;
    font-size: medium;
    font-weight: 600;
    padding-top: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
}
.rating-number{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

}
#rating-name{
    font-size: medium;
    font-weight: 300;
    margin-left: 10px;
}
.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 249px;
    padding: 10.5px;
    /* border: 1px solid rgb(188, 188, 188); */
    border-radius: 50px;
    margin: 8px auto;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .slider {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6FB8CF;
    color: white;
    width: 90px;
    height: 42px;
    border-radius: 40px;
    transition: transform 0.3s ease-in-out;
    font-weight: 300;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .option {
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-weight: 300;
  }
  #sort-by-text{
    font-weight: 300;
    margin-top: 20px;
    font-weight: 500;
  }
  #write-review-button{
    background-color: #6FB8CF;
    width: 200px;
    border-radius: 10px;
    color: white;
    padding: 8px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    /* padding-right: 20px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  #write-review-button:hover{
    background-color: #5e9caf;
  }
  #course-description-review{
    font-weight: 300;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 30px;
    font-size: large;
  }
  #write-icon{
    width: 25px;
  }
  #back-link{
    margin-top: 70px;
    border: #6FB8CF solid  1px;
    border-radius: 10px;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  #back-link:visited{
    color: inherit;
  }
  #back-link-div{
    margin-top: 70px;
    text-align: left;
    width: 550px;
  }
  #back-link:hover{
    background-color: #6FB8CF;
    color: white;

  }
  @media (max-width: 900px) {
    #back-link-div{
      max-width: 550px;
      width: 100%;
    }
    #back-link{
      margin-left: 20px;
    }
  
    .rating-number{
      width: fit-content;
      padding-left: 20px;
    }
    .slider-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 249px;
      padding: 10.5px;
      /* border: 1px solid rgb(188, 188, 188); */
      border-radius: 50px;
      margin: 8px ;
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .rating-number{
      margin-bottom: 10px;
      flex-direction: column;
      width: 80px;
    }
    #rating-name{
      margin-left: 0;
      margin-top: 10px;
    }
    #write-review-component-container{
      width: 90%;
      margin: auto;
    }
  
  }