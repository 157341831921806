#write-review-title{
    font-size: 40px;
    margin-top: 40px;
    font-weight: 300;
    text-align: center;
}
#write-review-div{
    border: 1px solid rgb(223, 223, 223);
    border-radius: 20px;
    width: 700px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    font-size: medium;
    font-weight: 300;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    background-color: white;
}
#star-ratings{
    display: flex;
    flex-direction: column;
}
#review-inputs{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}
#stars{
    font-size: 23px;
    letter-spacing: 3px;
}
#year{
    margin-right: 10px;
}
.review-professor{
    font-size: medium;
    margin-top: 5px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgb(223, 222, 222);
    width: 170px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}
.review-experiences{
    font-size: medium;
    margin-top: 5px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgb(223, 222, 222);
    width: 470px;
    height: 100px;
    font-family:Arial, Helvetica, sans-serif;
    resize: none;
    margin-bottom: 10px;
}
.review-experiences:focus{
    outline-color: grey;
}
.review-professor:focus{
    outline-color: grey;
}
#star-div{
    margin-bottom: 10px;
}
#online-select{
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    margin-right: 20px;
    border: 1px solid rgb(211, 210, 210);
}
#online-select:focus{
    outline-color: grey;
}
#online-select option{
    background-color: white;
}
#select-options{
    display: flex;
    flex-direction: row;
}
#submit-div{
    width: 490px;
    display: flex;
    justify-content: end;
}
#submit-div div{
    background-color: #6FB8CF;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    color: white;
    margin-top: 20px;
    font-weight: 500;
    cursor: pointer;
}
#submit-div div:hover{
    background-color: #61a4b9;
}
@media (max-width: 900px) {
    .write-reviews-container{
        width: 100%;
    }
    #write-review-div{
        flex-direction: column;
        width: 90%;
        padding: 20px;
        padding-top: 30px;
        }
    #review-inputs{
        margin-left: 0;
    }
    #review-inputs textarea{
        width: 90%;
    }
    #select-options{
        flex-direction: column;
    }
    #submit-div{
        width: 90%;
        justify-content: start;
    }
}