.navbar{
    border-bottom: 1px solid rgb(208, 208, 208);
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    padding: 1.5rem 2rem;
}
.navbar-brand{
    text-decoration: none; /* Removes underline */
    color: inherit; /* Ensures the link doesn't have the default blue color */  
    padding: calc(1.5rem + 1px) 2rem;
}
/* #middle-navbar-links:hover{
    border-bottom: 5px solid rgb(197, 197, 197);
} */
#middle-navbar-links {
    position: relative; /* This ensures the pseudo-element is relative to this element */
    /* Other styles for #middle-navbar-links go here */
}

#middle-navbar-links::before {
    content: "";
    position: absolute;
    left: 50%; /* Starts in the middle */
    bottom: 0; /* Align it to the bottom */
    width: 0; /* Initially, no width */
    height: 4px; /* Same as your border */
    background-color: rgb(197, 197, 197);
    transition: all 0.3s ease; /* Transition all properties */
    transform: translateX(-50%); /* Center the starting point */
}
#middle-navbar-links:hover::before {
    width: 100%; /* On hover, fill the entire width */
    left: 0; /* Start from the very left edge */
    transform: translateX(0); /* Reset translation */
}

.navbar-middle{
    font-weight: 300;
    font-size: 15px;
}
.navbar-left, .navbar-right{
    font-weight: 700;
    width: 230px;
}
.navbar-left{
    font-family: 'Visby Round', sans-serif;
    font-weight: 100;
    font-size: larger;
    display: flex;
    align-items: center;
    height: 0px;
}
.navbar-left a{
    margin-left: 0px;
    padding-left: 0;
}
.navbar-right{
    text-align: right;
}
.hamburger-menu {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}
.bar {
    width: 25px;
    height: 3px;
    background-color: black;
}
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);  /* Semi-transparent background */
    display: flex;
    z-index: 1000;  /* Place the overlay above other elements */
    margin-top: 4rem;
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
}

.mobile-menu {
    width: 100%;
    height: fit-content;
    background-color: white;
    padding: 20px;
    text-align: left;
    border-top: 1px solid #ededed;
}
.mobile-link{
    display: block;
    color: black;
    text-decoration: none;
    padding: 15px 0;
    font-size: 1rem;
}

.mobile-menu a:last-child {
    border-bottom: none;
}
#icon{
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
}
@media (max-width: 1100px) {
    .navbar-middle {
        display: none;
    }
    .navbar-right {
        display: none;
    }
    .mobile-link{
        padding-left: 1rem;
    }
    .hamburger-menu {
        display: flex;
    }
    .navbar-brand{
        padding-left: 0px;
    }

}
